import React from "react";
import "./App.css";

function App() {
    return (
        <div className="App">
            <header className="header">
                <h1>Drop Stitch</h1>
                <h2>A better way to follow craft patterns</h2>
            </header>
        </div>
    );
}

export default App;
